@import '~antd/lib/style/themes/default.less';
@import "~antd/dist/antd.less";
@primary-color: #9254de;

html,
body {
  overflow: overlay;
  overflow: -moz-scrollbars-none;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(143, 155, 168, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(59, 75, 89, 0.2);
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.ant-form {
  label {
    font-weight: bold;
  }

  .ant-input-borderless {
    border-color: transparent;
    padding-left: 0;
  }
  .ant-select-borderless {
    border-color: transparent;

    .ant-select-selector {
      padding-left: 0;
    }
    &.ant-select-disabled {
      .ant-select-arrow {
        display: none;
      }
      .ant-select-selection-item {
        color: rgba(0, 0, 0, 0.85);
        cursor: default;
      }

      &.ant-select-multiple {
        margin: 0 -7px;
      }
    }
  }

  .ant-picker {
    transition: all 0.3s;
  }

  .ant-picker-borderless {
    padding-left: 0;
    border-color: transparent;

    &.ant-picker-disabled {
      cursor: default;
      .ant-picker-suffix {
        display: none;
      }
      .ant-picker-input > input[disabled] {
        color: rgba(0, 0, 0, 0.85);
        cursor: default;
      }
    }
  }
}

.block-picker  {
  box-shadow: none !important;

  > div {
    border-radius: 6px !important;

    &:nth-child(2) {
      box-shadow: 0 0 1px #333;
    }
    &:nth-child(3) {
      > div > span > div {
        box-shadow: 0 0 1px #333;
      }
      > div:nth-child(2) {
        border-radius: none;
      }
    }
  }
}

.ant-drawer-footer.fixed {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;

  .actions-left {
    float: left;
  }
  .actions-right {
    float: right;
  }

  .create-another {
    padding: 5px;

    .create-another-label {
      margin-right: 3px;
      margin-left: 6px;
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }
}

.ant-form-item {
  &.highlighted * {
    color: #9254de;
  }
}
.ant-breadcrumb {
  margin-top: 2rem;
  margin-left: 24px;
}

.ant-select-item.ant-select-item-option-disabled .subtle {
  color: rgba(0, 0, 0, 0.25) !important;
}